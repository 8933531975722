var columns = [{
  title: '类型',
  dataIndex: 'type',
  key: 'type',
  width: '10%',
  scopedSlots: {
    customRender: 'type'
  }
}, {
  title: 'request',
  dataIndex: 'requestData',
  key: 'requestData',
  width: '40%',
  scopedSlots: {
    customRender: 'requestData'
  }
}, {
  title: 'response',
  dataIndex: 'responseData',
  key: 'responseData',
  width: '40%',
  scopedSlots: {
    customRender: 'responseData'
  }
}, {
  title: '时间',
  dataIndex: 'createTime',
  key: 'createTime',
  width: '10%',
  scopedSlots: {
    customRender: 'createTime'
  }
}];
export { columns };