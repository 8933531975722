var searchKeys = [{
  key: "create_time",
  label: "创建时间",
  placeholder: ['开始时间', '截止时间'],
  required: false,
  rules: [],
  dates: true
}, {
  key: "request_data",
  label: "请求数据",
  placeholder: "请求关键字",
  required: false,
  rules: [],
  input: true
}, {
  key: "response_data",
  label: "返回数据",
  placeholder: "返回关键字",
  required: false,
  rules: [],
  input: true
}, {
  key: "type",
  label: "类型",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };